import React, { FC } from 'react';
import { usePostsContainer } from 'components/posts/context';
import DomUtils from 'app/utils/dom';
import dayjs from 'dayjs';
import DateUtils from 'app/utils/date';
import { Button } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';
import PostsLayoutTopBarMonthSwitcher from './PostsLayoutTopBarMonthSwitcher';
import PostsConfig from 'app/config/posts';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import PostsDatePicker from './PostsDatePicker';

const MIN_DATE = dayjs()
  .subtract(PostsConfig.MIN_PREVIOUS_NUMBER_OF_YEARS, 'year')
  .startOf('year');

const MAX_MONTH_DATE_TO_SELECT = dayjs()
  .add(PostsConfig.MAX_NEXT_NUMBER_OF_YEARS, 'year')
  .endOf('year');

const PostsLayoutTobBarDateControls: FC = () => {
  const postsContainer = usePostsContainer();
  const { t } = useTranslation();

  const { activeView, isRangeDateViewActive, setIsRangeDateViewActive } =
    usePostsLayoutState();

  useEffectOnce(() => {
    setIsRangeDateViewActive(
      !DateUtils.isDateRangeMatchesMonth(
        postsContainer.filterState.startDate,
        postsContainer.filterState.endDate,
      ),
    );
  });

  function scrollToToday() {
    const today = document.querySelector<HTMLDivElement>(
      '[data-is-today="true"]',
    );
    const scrollCalendar = document.querySelector<HTMLDivElement>(
      '[data-name="monthly-calendar"]',
    );
    const scrollList = document.querySelector<HTMLDivElement>(
      '[data-name="monthly-calendar-list"]',
    );
    const scroll = scrollCalendar
      ? scrollCalendar
      : scrollList
      ? scrollList
      : null;

    if (today && scroll) {
      DomUtils.scrollParentToChild(scroll, today, 65);
    }
  }

  function handleTodayClick() {
    const calendarRangeContainsCurrentDate = dayjs().isBetween(
      dayjs(postsContainer.filterState.startDate),
      dayjs(postsContainer.filterState.endDate),
      'day',
      '[]',
    );

    if (calendarRangeContainsCurrentDate) {
      scrollToToday();
      return;
    }

    postsContainer.changeFilter(
      {
        key: 'startDate',
        value: DateUtils.toDateString(dayjs().startOf('month')),
      },
      {
        key: 'endDate',
        value: DateUtils.toDateString(dayjs().endOf('month')),
      },
    );
  }

  return (
    <>
      <PostsDatePicker
        startDate={dayjs(postsContainer.filterState.startDate)}
        endDate={dayjs(postsContainer.filterState.endDate)}
        onChange={(params) =>
          postsContainer.changeFilter(
            {
              key: 'startDate',
              value: DateUtils.toDateString(params.startDate),
            },
            {
              key: 'endDate',
              value: DateUtils.toDateString(params.endDate),
            },
          )
        }
      />
      {!isRangeDateViewActive &&
        ['list', 'calendar', 'board'].includes(activeView) && (
          <PostsLayoutTopBarMonthSwitcher
            minDate={MIN_DATE}
            maxDate={MAX_MONTH_DATE_TO_SELECT}
          />
        )}
      {['list', 'calendar'].includes(activeView) && (
        <Button
          data-name="posts-calendar-today"
          size={'medium'}
          variant="secondary"
          onClick={handleTodayClick}
          onMouseEnter={() => postsContainer.prefetchPostsForMonth(dayjs())}
        >
          {t('today')}
        </Button>
      )}
    </>
  );
};

export default PostsLayoutTobBarDateControls;
