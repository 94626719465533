import React from 'react';
import { Button, DropdownMenu, Icon } from '@kontentino/ui';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { useTranslation } from 'react-i18next';
import { PostDetail } from 'types/PostDetail';
import { getMediaDownloadSizes } from 'app/modules/posts/utils/getMediaDownloadSizes';
import { createSearchFromParams, openBlank } from 'utils/url';
import config from 'config';
import { MediaAttachmentSize } from 'types/Attachments';
import MediaCountLabel from 'app/components/mediaAttachmentsDownload/components/MediaCountLabel';

type Props = {
  post: PostDetail;
  token: string;
};

const PublicPostPreviewDownloadAssetsButton: React.FC<Props> = ({
  post,
  token,
}) => {
  const mediaDownloadSizes = post?.type ? getMediaDownloadSizes(post.type) : [];
  const { t } = useTranslation();

  function downloadAssets(size: MediaAttachmentSize) {
    openBlank(
      `${config.PUBLIC_API_SERVICE_ENDPOINT}/post/${
        post.id
      }/downloadAssets${createSearchFromParams({ size, token })}`,
    );
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button variant="secondary" size="small" className="tw-mb-3 tw-ml-auto">
          {t('downloadAssets')}
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="tw-z-[1001] tw-w-56">
          {post && <MediaCountLabel post={post} />}
          {mediaDownloadSizes.map((size, index) => (
            <DropdownMenu.Item
              key={`download-options-${index}`}
              onClick={() => downloadAssets(size.value)}
            >
              <Icon icon={faArrowDownToLine}></Icon>
              {t(size.tKey)}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default PublicPostPreviewDownloadAssetsButton;
