import { get, post, put } from 'api/client';
import { Post, PublicPostDetail } from 'types/Post';
import { PostStatus } from '@kontentino/kontentino-constants/Posts';
import config from 'config';
import {
  constructFormState,
  PostComposerFormState,
} from 'app/modules/posts/modules/post/components/composer/utils/postComposerFormState';
import { PostPreview } from 'types/PostPreview';
import { PostDetail } from 'types/PostDetail';
import { LinkInfo } from 'types/LinkInfo';
import { jsonToFormData } from 'utils/formData';
import { createSearchFromParams, openBlank, prependHttp } from 'utils/url';
import { ActivityLog } from 'types/ActivityLog';
import { sanitizeNotAllowedMediumKeys } from 'utils/post';
import { PostExportVariant } from 'constants/post';
import { EXTENDED_TIMEOUT } from 'constants/request';
import { getExtendedTimeout } from 'utils/request';
import DateUtils from 'app/utils/date';
import { CancelToken } from 'axios';
import { User } from 'types/User';

export type AssignTasksParams = {
  posts: number[];
  users: number[];
  message: string;
  isUrgent: boolean;
  canSendEmail: boolean;
};

export type ScheduleParams = {
  ids: number[];
  chosenMethod: 'publish' | 'export';
};

export type ScheduleResponse = Record<
  string,
  { status: boolean; message: string }
>;

export type SchedulePublishNowParams = {
  id: number;
  date: string;
  time: string;
};

export type UpdatePostParams = {
  id: number;
  fields: Partial<Post>;
};

export type ToggleAssignedTaskCompletionParams = {
  userId: number;
  taskId: number;
  completed: boolean;
};

type FetchLinkInfoParams = {
  pageId: number;
  url: string;
  cancelToken?: CancelToken;
};

type CheckLinkDomainVerificationParams = FetchLinkInfoParams;

type ExportContentsParams = {
  pages: number[];
  type: PostExportVariant;
  dateFrom: string;
  dateTo: string;
  selectedPosts: number[];
  statuses: number[];
  types: string[];
  labels: number[];
  users: number[];
  project: number[];
};

export type PostScheduleValidationMessage = {
  message: string;
  code: number;
};

export type PostScheduleValidation = {
  postId: number;
  messages: {
    errors: PostScheduleValidationMessage[];
    warnings: PostScheduleValidationMessage[];
  };
  canExport: boolean;
  canSchedule: boolean;
  canPublishNow: boolean;
};

const PostApi = {
  save(form: PostComposerFormState): Promise<PostDetail> {
    const {
      id,
      date,
      project,
      page,
      tags,
      audienceRestrictions,
      translationText,
      ...rest
    } = form;

    const requestData = sanitizeNotAllowedMediumKeys({
      ...rest,
      date: date ? DateUtils.toDateString(date) : null,
      pageId: page.id,
      projectId: project?.id,
      tags: tags.map((tag) => tag.id),
      budget: Number.isFinite(Number(rest.budget)) ? Number(rest.budget) : null,
      translation_text: translationText.trim(),
      audience_restrictions: audienceRestrictions,
    });

    if (!!id) {
      return put(`posts/${id}`, requestData, {
        baseURL: config.API_SERVICE_ENDPOINT,
      });
    }

    return post('posts', requestData, {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  async savePartial({
    postId,
    values,
  }: {
    postId: number;
    values: Partial<PostComposerFormState>;
  }): Promise<PostDetail> {
    const post = await PostApi.detail(postId);
    const formState = constructFormState({ post });

    return PostApi.save({
      ...formState,
      ...values,
    });
  },
  preview(id: number): Promise<PostPreview> {
    return get<PostPreview>('posts/ajaxGetPostPreviewData', {
      params: { id },
      timeout: getExtendedTimeout(),
    });
  },
  detail(postId: number): Promise<PostDetail> {
    return get<PostDetail>(`/posts/${postId}`, {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  publicPreview(id: string | number, token: string): Promise<PublicPostDetail> {
    return get(`post/${id}`, {
      baseURL: config.PUBLIC_API_SERVICE_ENDPOINT,
      params: { token },
    });
  },
  publicPreviewUrl(ids: number | number[]): Promise<string> {
    return get(
      `posts/previewLink?${(Array.isArray(ids) ? ids : [ids])
        .map((id) => `postIds[]=${id}`)
        .join('&')}`,
      {
        baseURL: config.API_SERVICE_ENDPOINT,
      },
    );
  },
  schedule({ ids, chosenMethod }: ScheduleParams): Promise<ScheduleResponse> {
    return post(
      '/posts2/ajaxSubmitPostsToSocialMedia',
      jsonToFormData({
        ids: JSON.stringify(ids),
        chosenMethod,
      }),
      { timeout: getExtendedTimeout(5) },
    );
  },
  scheduleDarkPost(id: number): Promise<{ id: string }> {
    const data = new FormData();

    data.append('id', id.toString());

    return post('/posts2/ajaxCreateUnpublishedPagePost', data, {
      timeout: getExtendedTimeout(5),
    });
  },
  publishNow(params: SchedulePublishNowParams): Promise<{ id: string }> {
    const data = new FormData();

    data.append('id', params.id.toString());
    data.append('date', params.date);
    data.append('time', params.time);

    return post('/posts2/ajaxPublishPostNow', data, {
      timeout: getExtendedTimeout(5),
    });
  },
  updatePost(params: UpdatePostParams): Promise<void> {
    const data = new FormData();
    data.append('id', params.id.toString());
    data.append('fields', JSON.stringify(params.fields));

    return post('/posts/ajaxUpdatePost', data);
  },
  validate(postIds: number[]): Promise<PostScheduleValidation[]> {
    return get<ApiResponse<PostScheduleValidation[]>>(
      `/posts/validate?postIds=${postIds.join(',')}`,
      {
        baseURL: config.API_SERVICE_ENDPOINT,
        timeout: getExtendedTimeout(),
      },
    ).then((res) => res.data);
  },
  toggleRequirement(postId: number, requirementId: number) {
    const data = new FormData();
    data.append('post_id', postId.toString());
    data.append('post_requirement_id', requirementId.toString());

    return post('posts2/ajaxTogglePostRequirementChecked', data);
  },
  toggleAssignedTaskCompletion(p: ToggleAssignedTaskCompletionParams) {
    const data = new FormData();
    data.append('task_id', p.taskId.toString());
    data.append('user_id', p.userId.toString());
    data.append('completed', p.completed ? `1` : `0`);

    return post('/posts/ajaxToggleAssignedTaskComplete', data);
  },
  fetchLinkInfo(params: FetchLinkInfoParams): Promise<LinkInfo> {
    return post(
      '/posts/ajaxFetchLink',
      jsonToFormData({
        ...params,
        url: prependHttp(params.url),
      }),
      {
        timeout: EXTENDED_TIMEOUT,
        cancelToken: params.cancelToken,
      },
    ).then((res: any) => {
      res.url = res.url ?? res['og:url'];
      res.title =
        res.title ?? res['og:title'] ?? res.card_tags?.['twitter:title'];

      res.site_name = res.site_name ?? res['og:site_name'];

      res.description =
        res.description ?? res.card_tags?.['twitter:description'];

      res.media = res.media ?? [];

      return res;
    });
  },
  checkLinkDomainVerification(
    params: CheckLinkDomainVerificationParams,
  ): Promise<{
    ownership_permissions: { can_customize_link_posts: boolean };
    id: string;
  }> {
    return post(
      'pages/ajaxCheckFacebookLinkDomainVerification',
      jsonToFormData({
        ...params,
        url: prependHttp(params.url),
      }),
    );
  },
  getActivityLog(potId: number): Promise<ActivityLog> {
    return get('/posts2/ajaxGetPostActivityLog', {
      params: { post_id: potId },
    });
  },
  async exportContents(params: ExportContentsParams) {
    const search = createSearchFromParams({
      type: params.type,
      pages: params.pages.join(),
      date_from: params.dateFrom,
      date_to: params.dateTo,
      project: params.project.length === 0 ? '-1' : params.project.join(),
      posts: params.selectedPosts.join(),
      status: params.statuses.join(),
      types: params.types?.join(),
      labels: params.labels.join(),
      users: params.users.join(),
    });

    openBlank(`/posts2/exportPosts${search}`);
  },
  copyPostToDate(postId: number, date: string): Promise<Post> {
    const params = new FormData();
    params.append('id', postId.toString());
    params.append('date', date);

    return post('/posts/ajaxCopyPostToDate', params);
  },
  editPostDate(postId: number, date: string): Promise<Post> {
    const params = new FormData();
    params.append('id', postId.toString());
    params.append('date', date);

    return post('/posts/ajaxChangePostDate', params);
  },
  clearPostDate(postId: number): Promise<Post> {
    const params = new FormData();
    params.append('id', postId.toString());

    return post('/posts/ajaxClearPostDate', params);
  },
  editPostStatus(params: { id: number; status: PostStatus }): Promise<Post> {
    const data = new FormData();

    data.append('id', params.id.toString());
    data.append('status', params.status.toString());

    return post('/posts/ajaxUpdatePostStatus', data);
  },
  getStatusOfScheduledPost(
    id: number,
  ): Promise<{ count: number; fb_posts: number; status: boolean }> {
    return get('/posts2/ajaxGetStatusOfScheduledPost', { params: { id } });
  },
  deletePost(id: number, deleteLocalPosts?: boolean) {
    const params = new FormData();
    params.append('id', id.toString());

    if (deleteLocalPosts) {
      params.append('forceDeleteLocalPosts', '1');
    }

    return post('/posts/ajaxDeletePost', params);
  },
  getPostRevisions(postId: number): Promise<
    {
      id: number;
      user: User;
      hasAttachments: boolean;
      createdAt: string;
    }[]
  > {
    return get(`posts/${postId}/revisions`, {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  getPostRevisionDetail(
    postId: number,
    revisionId: number,
  ): Promise<PostDetail> {
    return get<PostDetail>(`posts/${postId}/revisions/${revisionId}`, {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
};

export default PostApi;
