import React, { FC, ReactNode } from 'react';
import AiContentRemainingAttemptsBadge from 'app/modules/aiContent/components/AiContentRemainingAttemptsBadge';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import TextEditorAiContentPopupGenerateText from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentPopupGenerateText';
import { Button, Icon } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
type Props = {
  renderResultAction?: (text: string) => ReactNode;
};

const TextEditorAIContentPopup: FC<Props> = ({ renderResultAction }) => {
  const { queries } = useTextEditorAiContent();
  const { t } = useTranslation();

  return (
    <div
      className="tw-shadow tw-w-[480px] tw-rounded tw-border tw-border-grayscale-20 tw-bg-white"
      data-name="ai-content-popup"
    >
      <div className="tw-p-4 tw-pb-0">
        {!queries.generateText.isLoading && queries.generateText.data && (
          <Button
            variant="plain"
            className="tw-mb-4 tw-capitalize"
            data-name="ai-content-back"
            iconBefore={
              <Icon icon={faChevronLeft} className="tw-text-grayscale-100" />
            }
            onClick={() => queries.generateText.reset()}
          >
            {t('back')}
          </Button>
        )}
        <div className="tw-flex tw-justify-between">
          <div className="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-text-grayscale-180">
            <span>{t('aiContent')}</span>
          </div>
          <div>
            <AiContentRemainingAttemptsBadge options={queries.options} />
          </div>
        </div>
      </div>
      <TextEditorAiContentPopupGenerateText
        renderResultAction={renderResultAction}
      />
    </div>
  );
};

export default TextEditorAIContentPopup;
