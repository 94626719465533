import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Skeleton from 'react-loading-skeleton';

const SetupAccount = lazy(
  () => import('app/modules/onboarding/modules/setupAccount'),
);
const SetupActivities = lazy(
  () => import('app/modules/onboarding/modules/setupActivities'),
);

export const onboardingRouter: RouteObject = {
  path: routeConfig.onboarding.path,
  element: (
    <Suspense fallback={<Skeleton width="100%" height="100%" />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Navigate to={routeConfig.onboarding.routes.initSetupAccount.path} />
      ),
    },
    {
      path: routeConfig.onboarding.routes.initSetupAccount.path,
      element: <SetupAccount />,
    },
    {
      path: routeConfig.onboarding.routes.setupActivities.path,
      element: <SetupActivities />,
    },
  ],
};
