import { FC, useMemo } from 'react';
import {
  ActivityIndicator,
  Button,
  Chip,
  Divider,
  Icon,
  IconButton,
  Tooltip,
} from '@kontentino/ui';
import { faMobile } from '@fortawesome/pro-regular-svg-icons/faMobile';
import { faSidebar } from '@fortawesome/pro-regular-svg-icons/faSidebar';
import { usePostsContainer } from 'components/posts/context';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';
import PostsLayoutContentBarLabels from 'app/modules/posts/components/postsLayout/postsLayoutContentBar/PostsLayoutContentBarLabels';
import { getPostsCurrencyBudgets } from 'app/modules/posts/utils/getPostsCurrencyBudgets';
import { useCurrencies } from 'modules/listings/listingsSelectors';
import { useTranslation } from 'react-i18next';
import { useUser } from 'modules/user/userSelector';
import { usePost } from 'modules/post/postSelector';
import { MODULE_STATUS } from 'constants/modules';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import DemoContentToggle from '../DemoContentToggle';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
const MAXIMUM_BUDGET_CURRENCIES_LIMIT = 3;

const PostsLayoutContentBar: FC = () => {
  const { posts, filterState, changeFilter, meta } = usePostsContainer();
  const {
    activeView,
    leftPanelOpen,
    gridMobileViewActive,
    postsIdeasActive,
    togglePostsIdeas,
    toggleLeftPanel,
    toggleGridMobileView,
  } = usePostsLayoutState();
  const currencies = useCurrencies();
  const currentUser = useUser();
  const { isTrial } = useSubscriptionInfo();

  const { totalCount, status: postsStatus } = usePost();
  const currencyBudgets = useMemo(
    () => getPostsCurrencyBudgets(posts.allPosts, currencies),
    [posts, currencies],
  );
  const { t } = useTranslation();
  const budgetLabel = [...currencyBudgets]
    .slice(0, MAXIMUM_BUDGET_CURRENCIES_LIMIT)
    .map((budget) => budget.label)
    .join(' • ');
  const moreBudgets = [...currencyBudgets].slice(
    MAXIMUM_BUDGET_CURRENCIES_LIMIT,
  );
  const isAssignedButtonActive = filterState.selectedUserIds.includes(
    currentUser.id,
  );
  const currentUserPostsCount =
    meta?.assignedUser.find((user) => user.id === currentUser.id)?.postCount ||
    0;

  return (
    <div className="tw-h-11 tw-flex tw-items-center tw-gap-2">
      <div
        className="tw-flex tw-items-center tw-gap-2"
        data-name="posts_content-bar-action-buttons"
      >
        {activeView === 'calendar' && (
          <IconButton
            variant={leftPanelOpen ? 'primary' : 'secondary'}
            size="small"
            icon={<Icon icon={faSidebar} />}
            onClick={toggleLeftPanel}
            data-name="posts_side-panel-toggle-button"
            data-cy="posts_side-panel-toggle-button"
          />
        )}
        {activeView !== 'board' && (
          <Button
            variant={postsIdeasActive ? 'primary' : 'secondary'}
            size="small"
            data-name="posts_post-ideas-toggle-button"
            iconAfter={
              <Chip variant={postsIdeasActive ? 'white' : 'grey'}>
                {posts.postIdeas.length}
              </Chip>
            }
            onClick={togglePostsIdeas}
            data-cy="posts_post-ideas-toggle-button"
          >
            {t('idea_other')}
          </Button>
        )}
        {currentUserPostsCount !== 0 && (
          <Button
            variant={isAssignedButtonActive ? 'primary' : 'secondary'}
            size="small"
            iconAfter={
              <Chip variant={isAssignedButtonActive ? 'white' : 'grey'}>
                {currentUserPostsCount}
              </Chip>
            }
            onClick={() =>
              changeFilter({
                key: 'selectedUserIds',
                value: isAssignedButtonActive ? [] : [currentUser.id],
              })
            }
            data-name="assign_to_me-content_bar-button"
            data-cy="assign_to_me-content_bar-button"
          >
            {t('assignedToMe')}
          </Button>
        )}
        {activeView === 'grid' && (
          <>
            <Divider orientation="vertical" className="!tw-h-[20px]" />
            <IconButton
              variant={gridMobileViewActive ? 'primary' : 'secondary'}
              size="small"
              icon={<Icon icon={faMobile} />}
              onClick={toggleGridMobileView}
              data-name="grid-mobile-preview-button"
            />
          </>
        )}

        {isTrial && (
          <UserPermissionGate scopes={UserPermission.PAGE_CREATE}>
            <DemoContentToggle />
          </UserPermissionGate>
        )}
      </div>

      {postsStatus === MODULE_STATUS.Loading && <ActivityIndicator />}
      <div className="tw-ml-auto tw-flex tw-items-center tw-gap-3">
        <div className="tw-text-sm">
          <span className="tw-text-grayscale-100">{t('post_other')}:</span>{' '}
          <span>{totalCount}</span>
        </div>
        <div className="tw-text-sm">
          <span className="tw-text-grayscale-100">{t('budget')}:</span>{' '}
          <span>{!!budgetLabel.length ? budgetLabel : 0}</span>
          {moreBudgets.length !== 0 && (
            <>
              {' • '}
              <Tooltip
                content={
                  <>
                    {moreBudgets.map((budget) => (
                      <span className="tw-block" key={budget.code}>
                        {budget.label}
                      </span>
                    ))}
                  </>
                }
                placement="bottom"
              >
                <span className="tw-cursor-pointer tw-underline">More</span>
              </Tooltip>
            </>
          )}
        </div>
        {!!meta?.label.length && (
          <>
            <Divider orientation="vertical" className="tw-h-[20px]" />
            <PostsLayoutContentBarLabels
              labels={meta.label}
              selectedLabelsIds={filterState.selectedLabels}
              onSelectLabel={(id) =>
                changeFilter({ key: 'selectedLabels', value: id })
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PostsLayoutContentBar;
