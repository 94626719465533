import { Button, DropdownMenu, Icon } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faFeather } from '@fortawesome/pro-regular-svg-icons/faFeather';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faMicrochipAi } from '@fortawesome/pro-regular-svg-icons/faMicrochipAi';
import { faPhotoFilm } from '@fortawesome/pro-regular-svg-icons/faPhotoFilm';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons/faShareNodes';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { faSuitcase } from '@fortawesome/pro-regular-svg-icons/faSuitcase';
import { UserPermission } from 'constants/userPermission';
import Link from 'components/shared/Link';
import routes, { modalRoutes } from 'constants/routes';
import UserPermissionGate from 'components/UserPermissionGate';
import { usePostsCreateNew } from 'app/modules/posts/hooks/usePostsCreateNew';
import FeatureBadge from 'app/components/FeatureBadge';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions } from 'app/hooks/useActions';
import { WrapComponent } from 'app/components/WrapComponent';
const PostsTopbarAddNewDropdown: FC = () => {
  const { getCreateInspirationUrl, getCreatePostUrl } = usePostsCreateNew();
  const { t } = useTranslation();
  const { profileActions } = useActions('add-new-dropdown');

  const dropdownOptions = [
    ...[
      {
        key: 'post',
        label: t('post', { count: 1 }),
        icon: faPhotoFilm,
        permissionKeys: [UserPermission.POST_CREATE],
        link: getCreatePostUrl(),
      },
      {
        key: 'social-profile',
        label: t('profile'),
        icon: faShareNodes,
        permissionKeys: [profileActions.add.scopes],
        onClick: profileActions.add.perform,
      },
      {
        key: 'profile-group',
        label: t('group'),
        icon: faLayerGroup,
        permissionKeys: [UserPermission.PAGE_PRESETS_MANAGE],
        link: { hash: modalRoutes.MANAGE_PROFILE_GROUP },
      },
      {
        key: 'inspiration',
        label: t('inspiration'),
        icon: faFeather,
        permissionKeys: [
          UserPermission.INSPIRATION_CREATE,
          UserPermission.INSPIRATION_CREATE_FROM_CALENDAR,
        ],
        link: getCreateInspirationUrl(),
      },
      {
        key: 'content-plan',
        label: t('contentPlan'),
        icon: faSuitcase,
        permissionKeys: [UserPermission.PROJECT_CREATE],
        link: { hash: modalRoutes.NEW_CONTENT_PLAN },
      },
      {
        key: 'ai-content',
        label: (
          <>
            {t('aiContent')}
            <FeatureBadge className="tw-ml-auto tw-uppercase">
              <Icon icon={faSparkles} />
              {t('new')}
            </FeatureBadge>
          </>
        ),
        icon: faMicrochipAi,
        permissionKeys: [UserPermission.POST_CREATE],
        link: { pathname: routes.AI_CONTENT },
      },
    ],
  ];

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button
          className="tw-h-[36px]"
          variant="primary"
          data-name="posts-add-new"
          data-cy="posts-add-new-dropdown"
          iconAfter={<Icon icon={faChevronDown} />}
        >
          New
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10 tw-w-56">
        {dropdownOptions.map((option) => (
          <UserPermissionGate
            scopes={option.permissionKeys}
            key={`create-new-${option.key}-option`}
          >
            <WrapComponent
              if={!!option.link}
              with={(children) => {
                if (!option.link) return children;
                return (
                  <Link to={option.link} onClick={option.onClick}>
                    {children}
                  </Link>
                );
              }}
              elseWith={(children) => (
                <div onClick={option.onClick}>{children}</div>
              )}
            >
              <DropdownMenu.Item
                data-name={`posts-add-new-${option.key}`}
                data-cy={`posts-add-new-${option.key}-button`}
                className="tw-group"
              >
                <Icon icon={option.icon} />
                {option.label}
              </DropdownMenu.Item>
            </WrapComponent>
          </UserPermissionGate>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default PostsTopbarAddNewDropdown;
