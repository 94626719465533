import React, { FC } from 'react';
import { Button, Field, Icon, Select } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { GenerationsApi } from 'app/modules/posts/api/GenerationsApi';
import { queryKey } from 'constants/queryKey';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import TextGeneratedResults from 'app/modules/aiContent/components/results/TextGeneratedResults';
import AIGenerateButton from 'app/modules/aiContent/components/AIGenerateButton';
import ValidationScheme from 'app/constants/validationSchemes';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { useToast } from 'app/hooks/useToast';
import { ApiClientError } from 'api/client';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';

type Props = {
  onTranslated(text: string): void;
  getTextToTranslate(): string;
};

const schema = z.object({
  source: ValidationScheme.RequiredString({
    message: 'Source text is not allowed to be empty.',
  }),
  targetLanguage: ValidationScheme.RequiredString(),
});

const TextEditorTranslationPopup: FC<Props> = ({
  getTextToTranslate,
  onTranslated,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { accountPlanType } = useSubscriptionInfo();

  const form = useForm<{
    source: string;
    targetLanguage: string;
  }>({
    defaultValues: {
      source: '',
      targetLanguage: '',
    },
    resolver: zodResolver(schema),
  });

  const transformText = useMutation(GenerationsApi.transformText, {
    onSuccess: (data) => {
      onTranslated(data.text);
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage || t('somethingWentWrong'));
    },
  });
  const translateTextOptions = useQuery(
    queryKey.transformTextOptions(),
    GenerationsApi.getTransformTextOptions,
  );

  const onSubmit = form.handleSubmit((values) => {
    transformText.mutate({
      ...values,
      action: 'translate',
      planType: accountPlanType,
    });
  });

  return (
    <div
      className="tw-shadow tw-w-[480px] tw-rounded tw-border tw-border-grayscale-20 tw-bg-white"
      data-name="translate-text-popup"
    >
      <div className="tw-p-4 tw-pb-0">
        {!transformText.isLoading && transformText.data && (
          <Button
            variant="plain"
            className="tw-mb-4 tw-capitalize"
            data-name="translate-text-back"
            iconBefore={
              <Icon icon={faChevronLeft} className="tw-text-grayscale-100" />
            }
            onClick={() => transformText.reset()}
          >
            {t('back')}
          </Button>
        )}
        <div className="tw-flex tw-justify-between">
          <div className="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-text-grayscale-180">
            <span>{t('translateText')}</span>
          </div>
        </div>
        {!transformText.data && !transformText.isLoading && (
          <div className="tw-flex-grow tw-space-y-4 tw-py-4">
            <Field.Group>
              <Field.Label required>{t('outputLanguage')}</Field.Label>
              <Controller
                name="targetLanguage"
                control={form.control}
                render={({ field: { onChange, value, ref } }) => {
                  const languages =
                    translateTextOptions.data?.options.language ?? [];

                  const selectValue = languages.find(
                    (option) => option.value === value,
                  );

                  return (
                    <div
                      ref={ref}
                      tabIndex={0}
                      data-name="translate-text_language_select-wrapper"
                      data-cy="translate-text_language_select-wrapper"
                    >
                      <Select
                        options={languages}
                        onChange={(option) => onChange(option?.value ?? null)}
                        value={selectValue ?? null}
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        styles={{
                          menuPortal: (styles) => ({
                            ...styles,
                            zIndex: 1010,
                          }),
                        }}
                      />
                    </div>
                  );
                }}
              />
              {form.formState.errors.targetLanguage?.message && (
                <Field.Error>
                  {form.formState.errors.targetLanguage?.message}
                </Field.Error>
              )}
              {form.formState.errors.source?.message && (
                <Field.Error>
                  {form.formState.errors.source?.message}
                </Field.Error>
              )}
            </Field.Group>
          </div>
        )}
      </div>

      {(transformText.data || transformText.isLoading) && (
        <TextGeneratedResults
          suggestions={transformText.data ? [transformText.data] : []}
          isLoading={transformText.isLoading}
          classNames={{
            root: 'tw-overflow-y-auto tw-flex-grow tw-h-[374px]',
            loadingImg: 'tw-h-[8.5rem]',
            emptyImg: 'tw-h-[8.5rem]',
          }}
        />
      )}
      {!transformText.isLoading && !transformText.data && (
        <div className="tw-flex tw-justify-end tw-border-t tw-border-grayscale-20 tw-p-4">
          <AIGenerateButton
            onClick={() => {
              form.setValue('source', getTextToTranslate());
              onSubmit();
            }}
            isLoading={transformText.isLoading}
          >
            {t('translate')}
          </AIGenerateButton>
        </div>
      )}
    </div>
  );
};

export default TextEditorTranslationPopup;
