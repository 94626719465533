import React, { FC, forwardRef } from 'react';
import { Editor } from '@tiptap/react';
import { PageNumberType, TYPE } from '@kontentino/kontentino-constants/Pages';
import CharacterCounter, {
  CharacterCounterCountConfig,
} from 'app/modules/textEditor/components/optionsBar/CharacterCounter';
import {
  TextEditorFeature,
  TextEditorFeatures,
} from 'app/modules/textEditor/constants/textEditorFeature';
import OptionButton, {
  OptionButtonProps,
} from 'app/modules/textEditor/components/optionsBar/OptionButton';
import { DropzoneState } from 'react-dropzone';
import {
  Button,
  Icon,
  mergeTailwindClasses,
  Popover,
  Tooltip,
} from '@kontentino/ui';
import { faArrowRightToArc } from '@fortawesome/pro-regular-svg-icons/faArrowRightToArc';
import { faArrowRotateLeft } from '@fortawesome/pro-regular-svg-icons/faArrowRotateLeft';
import { faArrowRotateRight } from '@fortawesome/pro-regular-svg-icons/faArrowRotateRight';
import { faAt } from '@fortawesome/pro-regular-svg-icons/faAt';
import { faHashtag } from '@fortawesome/pro-regular-svg-icons/faHashtag';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faSmile } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import clsx from 'clsx';
import EmojiPicker from 'app/components/EmojiPicker';
import DirectFbMention from 'components/shared/DirectFbMention';
import { useTranslation } from 'react-i18next';
import TextEditorAIContentPopup from 'app/modules/textEditor/components/optionsBar/TextEditorAIContentPopup';
import TextEditorConvertor from '../utils/textEditorConvertor';
import { PopoverContentProps } from '@radix-ui/react-popover';
import HashtagsPresets from 'app/modules/textEditor/components/optionsBar/HashtagsPresets';
import LinkShortener from 'app/modules/textEditor/components/optionsBar/LinkShortener';
import { faLanguage } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import TextEditorTranslationPopup from 'app/modules/textEditor/components/optionsBar/TextEditorTranslationPopup';
import useBoolean from 'utils/hooks/useBoolean';

type Props = {
  editor: Editor;
  pageType?: PageNumberType;
  features: TextEditorFeatures;
  isDisabled?: boolean;
  dropzone: DropzoneState;
  optionsBarConfig?: OptionsBarConfig;
  dataNamePrefix?: string;
  charactersLimit?: number;
  className?: string;
  pageId: number | undefined;
};

export type OptionsBarConfig = {
  additionalButtons?: OptionButtonProps[];
  charactersLimit?: number;
  count?: CharacterCounterCountConfig;
  popover?: {
    avoidCollisions?: boolean;
    initialOpen?: TextEditorFeature;
  };
};

const DEFAULT_POPOVER_CONTENT_PROPS = {
  className: '!tw-z-[1001] tw-min-h-[300px] tw-w-[480px]',
  avoidCollisions: false,
};

const OptionsBar: FC<Props> = forwardRef<HTMLDivElement, Props>(
  (
    {
      editor,
      pageType,
      features,
      isDisabled,
      optionsBarConfig,
      dropzone,
      dataNamePrefix,
      className,
      pageId,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [translationPopoverOpen, setTranslationPopoverOpen] = useBoolean(
      optionsBarConfig?.popover?.initialOpen === TextEditorFeature.Translation,
    );

    function getAdditionalButtons() {
      return (
        optionsBarConfig?.additionalButtons?.map((props, index) => (
          <OptionButton
            {...props}
            dataName={
              props.dataName ? `${dataNamePrefix}-${props.dataName}` : undefined
            }
            key={`additional-button-${index}`}
          />
        )) ?? []
      );
    }

    function getPopoverContentProps({
      className,
      avoidCollisions,
      ...rest
    }: PopoverContentProps = {}): PopoverContentProps {
      return {
        ...DEFAULT_POPOVER_CONTENT_PROPS,
        className: mergeTailwindClasses(
          clsx(DEFAULT_POPOVER_CONTENT_PROPS.className, className),
        ),
        avoidCollisions:
          optionsBarConfig?.popover?.avoidCollisions ??
          avoidCollisions ??
          DEFAULT_POPOVER_CONTENT_PROPS.avoidCollisions,
        ...rest,
      };
    }

    return (
      <div
        className={mergeTailwindClasses(
          clsx(' tw-relative tw-flex tw-items-center tw-gap-2', className),
        )}
        id="editor-content-options-bar"
      >
        {features.includes(TextEditorFeature.UndoRedo) && (
          <div className="tw-flex">
            <OptionButton
              name={t('textEditor.undo')}
              isDisabled={isDisabled || !editor.can().undo?.()}
              onClick={() => editor.chain().focus().undo().run()}
              dataName={`${dataNamePrefix}-option-undo`}
              className="tw-rounded-br-none tw-rounded-tr-none hover:enabled:tw-text-white"
              variant="secondary"
            >
              <Icon icon={faArrowRotateLeft} />
            </OptionButton>
            <OptionButton
              name={t('textEditor.redo')}
              isDisabled={isDisabled || !editor.can().redo?.()}
              onClick={() => editor.chain().focus().redo().run()}
              dataName={`${dataNamePrefix}-option-redo`}
              className="tw-rounded-bl-none tw-rounded-tl-none hover:enabled:tw-text-white"
              variant="secondary"
            >
              <Icon icon={faArrowRotateRight} />
            </OptionButton>
          </div>
        )}
        {features.includes(TextEditorFeature.AIContent) && (
          <Popover.Root
            defaultOpen={
              optionsBarConfig?.popover?.initialOpen ===
              TextEditorFeature.AIContent
            }
          >
            <Popover.Trigger asChild>
              <OptionButton
                isDisabled={isDisabled}
                dataName={`${dataNamePrefix}-ai-content`}
                className="tw-w-auto tw-px-3 enabled:tw-text-primary-100 enabled:hover:tw-text-white"
                variant="tertiary"
              >
                <Icon icon={faSparkles} />
                <span className="tw-text-sm">{t('aiContent')}</span>
              </OptionButton>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                {...getPopoverContentProps({
                  avoidCollisions: true,
                })}
                side="bottom"
                align="start"
                sideOffset={4}
              >
                <TextEditorAIContentPopup
                  renderResultAction={(text) => (
                    <Tooltip content={t('insertToText')}>
                      <Button
                        onClick={() =>
                          editor.commands.insertContent(text, {
                            parseOptions: {
                              preserveWhitespace: true,
                            },
                          })
                        }
                        data-name={`ai-content_insert-text`}
                        variant="plain"
                        size="small"
                        className="tw-mr-3"
                      >
                        <Icon
                          icon={faArrowRightToArc}
                          className="tw-text-grayscale-100"
                        />
                        <span className="text-sm">{t('insert')}</span>
                      </Button>
                    </Tooltip>
                  )}
                />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
        {features.includes(TextEditorFeature.FileAttachments) && (
          <>
            <input {...dropzone.getInputProps()} />
            <OptionButton
              name={t('textEditor.attachments')}
              isDisabled={isDisabled}
              onClick={() => dropzone.open()}
              dataName={`${dataNamePrefix}-option-attach-file`}
            >
              <Icon icon={faPaperclip} />
            </OptionButton>
          </>
        )}
        {features.includes(TextEditorFeature.EmojiPicker) && (
          <Popover.Root
            defaultOpen={
              optionsBarConfig?.popover?.initialOpen ===
              TextEditorFeature.EmojiPicker
            }
          >
            <Popover.Trigger asChild>
              <OptionButton
                name={t('textEditor.emoji')}
                isDisabled={isDisabled}
                dataName={`${dataNamePrefix}-option-emoji`}
              >
                <Icon icon={faSmile} />
              </OptionButton>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                {...getPopoverContentProps({
                  avoidCollisions: true,
                  className: '!tw-w-auto',
                })}
              >
                <EmojiPicker
                  onEmojiSelect={(emoji) =>
                    editor.commands.insertContent(emoji.native)
                  }
                />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
        {pageType && features.includes(TextEditorFeature.HashtagsPresets) && (
          <Popover.Root
            defaultOpen={
              optionsBarConfig?.popover?.initialOpen ===
              TextEditorFeature.HashtagsPresets
            }
          >
            <Popover.Trigger asChild>
              <OptionButton
                name="Hashtags presets"
                isDisabled={isDisabled}
                dataName={`${dataNamePrefix}-hashtags-presets`}
              >
                <Icon icon={faHashtag} />
              </OptionButton>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content {...getPopoverContentProps()}>
                {pageId && (
                  <HashtagsPresets
                    pageId={pageId}
                    onCopyToText={(value) => {
                      editor
                        .chain()
                        .insertContent(TextEditorConvertor.textToHtml(value))
                        .setHardBreak()
                        .run();
                    }}
                    dataName="text-editor_hashtags-presets"
                    dataCy="text-editor_hashtags-presets"
                  />
                )}
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
        {features.includes(TextEditorFeature.LinkShortener) && (
          <Popover.Root
            defaultOpen={
              optionsBarConfig?.popover?.initialOpen ===
              TextEditorFeature.LinkShortener
            }
          >
            <Popover.Trigger asChild>
              <OptionButton
                name="Link shortener"
                isDisabled={isDisabled}
                dataName={`${dataNamePrefix}-link-shortener`}
              >
                <Icon icon={faLink} />
              </OptionButton>
            </Popover.Trigger>

            <Popover.Portal>
              <Popover.Content {...getPopoverContentProps()}>
                <LinkShortener
                  pageId={pageId}
                  onCopyToText={editor.commands.insertContent}
                  dataName="text-editor_link-shortener"
                  dataCy="text-editor_link-shortener"
                />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
        {pageType &&
          ([TYPE.FACEBOOK, TYPE.FACEBOOK_AD] as PageNumberType[]).includes(
            pageType,
          ) &&
          [
            TextEditorFeature.DirectFbMention,
            TextEditorFeature.PageMention,
          ].every((s) => features.includes(s)) && (
            <Popover.Root
              defaultOpen={
                optionsBarConfig?.popover?.initialOpen ===
                TextEditorFeature.DirectFbMention
              }
            >
              <Popover.Trigger asChild>
                <OptionButton
                  name="Direct mention"
                  isDisabled={isDisabled}
                  dataName={`${dataNamePrefix}-direct-mention`}
                >
                  <Icon icon={faAt} />
                </OptionButton>
              </Popover.Trigger>

              <Popover.Portal>
                <Popover.Content {...getPopoverContentProps()}>
                  <DirectFbMention
                    onCopyToText={editor.commands.insertContent}
                  />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          )}
        {features.includes(TextEditorFeature.Translation) && editor && (
          <Popover.Root
            open={translationPopoverOpen}
            onOpenChange={setTranslationPopoverOpen.set}
            defaultOpen={
              optionsBarConfig?.popover?.initialOpen ===
              TextEditorFeature.Translation
            }
          >
            <Popover.Trigger asChild>
              <OptionButton
                name={t('textEditor.translation')}
                isDisabled={isDisabled}
                dataName={`${dataNamePrefix}-translation`}
              >
                <Icon icon={faLanguage} />
              </OptionButton>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                {...getPopoverContentProps({
                  avoidCollisions: true,
                })}
                side="bottom"
                align="start"
                sideOffset={4}
              >
                <TextEditorTranslationPopup
                  getTextToTranslate={() => {
                    const from = editor.state.selection.$from.pos;
                    const to = editor.state.selection.$to.pos;

                    return from === to
                      ? editor.getText()
                      : editor.state.doc.textBetween(from, to, ' ');
                  }}
                  onTranslated={(translation) => {
                    const htmlTranslation =
                      TextEditorConvertor.textToHtml(translation);

                    const from = editor.state.selection.$from.pos;
                    const to = editor.state.selection.$to.pos;

                    if (from === to) {
                      editor
                        .chain()
                        .setContent('')
                        .insertContent(htmlTranslation)
                        .run();
                    } else {
                      editor
                        .chain()
                        .insertContentAt({ from, to }, htmlTranslation)
                        .run();
                    }
                    setTimeout(() => {
                      editor.commands.focus();
                    }, 50);
                    setTranslationPopoverOpen.off();
                  }}
                />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
        {getAdditionalButtons()}
        {features.includes(TextEditorFeature.CharacterCounter) && (
          <CharacterCounter
            count={
              optionsBarConfig?.count || {
                characters: editor.storage.characterCount.characters(),
                hashtags: editor.storage.characterCount.specials().hashtags,
                mentions: editor.storage.characterCount.specials().mentions,
              }
            }
            limit={optionsBarConfig?.charactersLimit}
          />
        )}
      </div>
    );
  },
);

export default OptionsBar;
